export const Countries = [
  {
    content: 'Finland',
    code: 'FI',
    id: 'Helsinki',
    uniq: 72,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fi',
      iso639_2: 'fin',
      content: 'Finnish',
      nativecontent: 'suomi',
    },
    imgPath: 'https://flagcdn.com/fi.svg',
  },
  {
    content: 'Latvia',
    code: 'LV',
    id: 'Riga',
    region: 'EU',
    uniq: 120,

    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'lv',
      content: 'Latvian',
    },
    imgPath: 'https://flagcdn.com/lv.svg',
  },
  {
    content: 'Afghanistan',
    code: 'AF',
    uniq: 3,
    id: 'Kabul',
    region: 'AS',
    currency: {
      code: 'AFN',
      content: 'Afghan afghani',
      symbol: '؋',
    },
    language: {
      code: 'ps',
      content: 'Pashto',
    },
    imgPath: 'https://flagcdn.com/af.svg',
  },
  {
    content: 'Åland Islands',
    code: 'AX',
    id: 'Mariehamn',
    uniq: 4,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'sv',
      content: 'Swedish',
    },
    imgPath: 'https://flagcdn.com/ax.svg',
  },
  {
    content: 'Albania',
    code: 'AL',
    id: 'Tirana',
    uniq: 5,

    region: 'EU',
    currency: {
      code: 'ALL',
      content: 'Albanian lek',
      symbol: 'L',
    },
    language: {
      code: 'sq',
      content: 'Albanian',
    },
    imgPath: 'https://flagcdn.com/al.svg',
  },
  {
    content: 'American Samoa',
    code: 'AS',
    id: 'Pago Pago',
    uniq: 6,

    region: 'OC',
    currency: {
      code: 'USD',
      content: 'United State Dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/as.svg',
  },
  {
    content: 'Andorra',
    code: 'AD',
    id: 'Andorra la Vella',
    region: 'EU',
    uniq: 7,
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'ca',
      content: 'Catalan',
    },
    imgPath: 'https://flagcdn.com/ad.svg',
  },
  {
    content: 'Angola',
    code: 'AO',
    id: 'Luanda',
    uniq: 8,

    region: 'AF',
    currency: {
      code: 'AOA',
      content: 'Angolan kwanza',
      symbol: 'Kz',
    },
    language: {
      code: 'pt',
      content: 'Portuguese',
    },
    imgPath: 'https://flagcdn.com/ao.svg',
  },
  {
    content: 'Anguilla',
    code: 'AI',
    id: 'The Valley',
    uniq: 9,

    region: 'NA',
    currency: {
      code: 'XCD',
      content: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ai.svg',
  },
  {
    content: 'Antigua and Barbuda',
    code: 'AG',
    id: "Saint John's",
    region: 'NA',
    uniq: 10,

    currency: {
      code: 'XCD',
      content: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ag.svg',
  },
  {
    content: 'Argentina',
    code: 'AR',
    id: 'Buenos Aires',
    uniq: 11,

    region: 'SA',
    currency: {
      code: 'ARS',
      content: 'Argentine peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/ar.svg',
  },
  {
    content: 'Armenia',
    code: 'AM',
    id: 'Yerevan',
    uniq: 12,

    region: 'AS',
    currency: {
      code: 'AMD',
      content: 'Armenian dram',
      symbol: null,
    },
    language: {
      code: 'hy',
      content: 'Armenian',
    },
    imgPath: 'https://flagcdn.com/am.svg',
  },
  {
    content: 'Aruba',
    code: 'AW',
    id: 'Oranjestad',
    region: 'SA',
    uniq: 13,

    currency: {
      code: 'AWG',
      content: 'Aruban florin',
      symbol: 'ƒ',
    },
    language: {
      code: 'nl',
      content: 'Dutch',
    },
    imgPath: 'https://flagcdn.com/aw.svg',
  },
  {
    content: 'Australia',
    code: 'AU',
    id: 'Canberra',
    region: 'OC',
    uniq: 14,

    currency: {
      code: 'AUD',
      content: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/au.svg',
  },
  {
    content: 'Austria',
    code: 'AT',
    id: 'Vienna',
    region: 'EU',
    uniq: 15,

    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'de',
      content: 'German',
    },
    imgPath: 'https://flagcdn.com/at.svg',
  },
  {
    content: 'Azerbaijan',
    code: 'AZ',
    id: 'Baku',
    uniq: 16,

    region: 'AS',
    currency: {
      code: 'AZN',
      content: 'Azerbaijani manat',
      symbol: null,
    },
    language: {
      code: 'az',
      content: 'Azerbaijani',
    },
    imgPath: 'https://flagcdn.com/az.svg',
  },
  {
    content: 'Bahamas',
    code: 'BS',
    id: 'Nassau',
    uniq: 17,

    region: 'NA',
    currency: {
      code: 'BSD',
      content: 'Bahamian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/bs.svg',
  },
  {
    content: 'Bahrain',
    code: 'BH',
    id: 'Manama',
    uniq: 18,

    region: 'AS',
    currency: {
      code: 'BHD',
      content: 'Bahraini dinar',
      symbol: '.د.ب',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/bh.svg',
  },
  {
    content: 'Bangladesh',
    code: 'BD',
    id: 'Dhaka',
    region: 'AS',
    uniq: 19,

    currency: {
      code: 'BDT',
      content: 'Bangladeshi taka',
      symbol: '৳',
    },
    language: {
      code: 'bn',
      content: 'Bengali',
    },
    imgPath: 'https://flagcdn.com/bd.svg',
  },
  {
    content: 'Barbados',
    code: 'BB',
    id: 'Bridgetown',
    uniq: 20,

    region: 'NA',
    currency: {
      code: 'BBD',
      content: 'Barbadian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/bb.svg',
  },
  // {
  //   content: 'Belarus',
  //   code: 'BY',
  //   id: 'Minsk',
  //   region: 'EU',
  //   uniq: 21,

  //   currency: {
  //     code: 'BYN',
  //     content: 'New Belarusian ruble',
  //     symbol: 'Br',
  //   },
  //   language: {
  //     code: 'be',
  //     content: 'Belarusian',
  //   },
  //   imgPath: 'https://flagcdn.com/by.svg',
  // },
  {
    content: 'Belgium',
    code: 'BE',
    id: 'Brussels',
    uniq: 22,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'nl',
      content: 'Dutch',
    },
    imgPath: 'https://flagcdn.com/be.svg',
  },
  {
    content: 'Belize',
    code: 'BZ',
    id: 'Belmopan',
    region: 'NA',
    uniq: 23,

    currency: {
      code: 'BZD',
      content: 'Belize dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/bz.svg',
  },
  {
    content: 'Benin',
    code: 'BJ',
    id: 'Porto-Novo',
    uniq: 24,

    region: 'AF',
    currency: {
      code: 'XOF',
      content: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/bj.svg',
  },
  {
    content: 'Bermuda',
    code: 'BM',
    id: 'Hamilton',
    uniq: 25,

    region: 'NA',
    currency: {
      code: 'BMD',
      content: 'Bermudian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/bm.svg',
  },
  {
    content: 'Bhutan',
    code: 'BT',
    id: 'Thimphu',
    uniq: 26,

    region: 'AS',
    currency: {
      code: 'BTN',
      content: 'Bhutanese ngultrum',
      symbol: 'Nu.',
    },
    language: {
      code: 'dz',
      content: 'Dzongkha',
    },
    imgPath: 'https://flagcdn.com/bt.svg',
  },
  {
    content: 'Bolivia (Plurinational State of)',
    code: 'BO',
    id: 'Sucre',
    uniq: 27,

    region: 'SA',
    currency: {
      code: 'BOB',
      content: 'Bolivian boliviano',
      symbol: 'Bs.',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/bo.svg',
  },
  {
    content: 'Bosnia and Herzegovina',
    code: 'BA',
    id: 'Sarajevo',
    uniq: 28,

    region: 'EU',
    currency: {
      code: 'BAM',
      content: 'Bosnia and Herzegovina convertible mark',
      symbol: null,
    },
    language: {
      code: 'bs',
      content: 'Bosnian',
    },
    imgPath: 'https://flagcdn.com/ba.svg',
  },
  {
    content: 'Botswana',
    code: 'BW',
    id: 'Gaborone',
    uniq: 29,

    region: 'AF',
    currency: {
      code: 'BWP',
      content: 'Botswana pula',
      symbol: 'P',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/bw.svg',
  },
  {
    content: 'Bouvet Island',
    code: 'BV',
    id: '',
    region: 'AN',
    uniq: 30,

    currency: {
      code: 'NOK',
      content: 'Norwegian krone',
      symbol: 'kr',
    },
    language: {
      code: 'no',
      content: 'Norwegian',
    },
    imgPath: 'https://flagcdn.com/bv.svg',
  },
  {
    content: 'Brazil',
    code: 'BR',
    id: 'Brasília',
    uniq: 31,

    region: 'SA',
    currency: {
      code: 'BRL',
      content: 'Brazilian real',
      symbol: 'R$',
    },
    language: {
      code: 'pt',
      content: 'Portuguese',
    },
    imgPath: 'https://flagcdn.com/br.svg',
  },
  {
    content: 'British Indian Ocean Territory',
    code: 'IO',
    id: 'Diego Garcia',
    uniq: 32,

    region: 'AF',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/io.svg',
  },
  {
    content: 'U.S.',
    code: 'UM',
    uniq: 235,

    id: '',
    region: 'NA',

    currency: {
      code: 'USD',
      content: 'United States Dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/um.svg',
  },
  {
    content: 'United States Minor Outlying Islands',
    code: 'UM',
    uniq: 236,

    id: '',
    region: 'NA',

    currency: {
      code: 'USD',
      content: 'United States Dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/um.svg',
  },
  {
    content: 'Virgin Islands (British)',
    code: 'VG',
    id: 'Road Town',

    region: 'NA',
    currency: {
      code: 'USD',
      uniq: 269,

      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/vg.svg',
  },
  {
    content: 'Virgin Islands (U.S.)',
    code: 'VI',
    id: 'Charlotte Amalie',
    uniq: 270,

    region: 'NA',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/vi.svg',
  },
  {
    content: 'Brunei Darussalam',
    code: 'BN',
    id: 'Bandar Seri Begawan',
    region: 'AS',
    uniq: 33,

    currency: {
      code: 'BND',
      content: 'Brunei dollar',
      symbol: '$',
    },
    language: {
      code: 'ms',
      content: 'Malay',
    },
    imgPath: 'https://flagcdn.com/bn.svg',
  },
  {
    content: 'Bulgaria',
    code: 'BG',
    id: 'Sofia',
    region: 'EU',
    uniq: 34,

    currency: {
      code: 'BGN',
      content: 'Bulgarian lev',
      symbol: 'лв',
    },
    language: {
      code: 'bg',
      content: 'Bulgarian',
    },
    imgPath: 'https://flagcdn.com/bg.svg',
  },
  {
    content: 'Burkina Faso',
    code: 'BF',
    id: 'Ouagadougou',
    uniq: 35,

    region: 'AF',
    currency: {
      code: 'XOF',
      content: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/bf.svg',
  },
  {
    content: 'Burundi',
    code: 'BI',
    id: 'Bujumbura',
    uniq: 36,

    region: 'AF',
    currency: {
      code: 'BIF',
      content: 'Burundian franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/bi.svg',
  },
  {
    content: 'Cambodia',
    code: 'KH',
    id: 'Phnom Penh',
    region: 'AS',
    uniq: 37,

    currency: {
      code: 'KHR',
      content: 'Cambodian riel',
      symbol: '៛',
    },
    language: {
      code: 'km',
      content: 'Khmer',
    },
    imgPath: 'https://flagcdn.com/kh.svg',
  },
  {
    content: 'Cameroon',
    code: 'CM',
    id: 'Yaoundé',
    region: 'AF',
    uniq: 38,

    currency: {
      code: 'XAF',
      content: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/cm.svg',
  },
  {
    content: 'Cabo Verde',
    code: 'CV',
    id: 'Praia',
    region: 'AF',
    uniq: 39,

    currency: {
      code: 'CVE',
      content: 'Cape Verdean escudo',
      symbol: 'Esc',
    },
    language: {
      code: 'pt',
      iso639_2: 'por',
      content: 'Portuguese',
      nativecontent: 'Português',
    },
    imgPath: 'https://flagcdn.com/cv.svg',
  },
  {
    content: 'Cayman Islands',
    code: 'KY',
    id: 'George Town',
    uniq: 40,

    region: 'NA',
    demonym: 'Caymanian',
    currency: {
      code: 'KYD',
      content: 'Cayman Islands dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ky.svg',
  },
  {
    content: 'Central African Republic',
    code: 'CF',
    id: 'Bangui',
    uniq: 41,

    region: 'AF',
    currency: {
      code: 'XAF',
      content: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/cf.svg',
  },
  {
    content: 'Chad',
    code: 'TD',
    id: "N'Djamena",
    uniq: 42,

    region: 'AF',
    currency: {
      code: 'XAF',
      content: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/td.svg',
  },
  {
    content: 'Chile',
    code: 'CL',
    id: 'Santiago',
    uniq: 43,

    region: 'SA',
    currency: {
      code: 'CLP',
      content: 'Chilean peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      iso639_2: 'spa',
      content: 'Spanish',
      nativecontent: 'Español',
    },
    imgPath: 'https://flagcdn.com/cl.svg',
  },
  {
    content: 'China',
    code: 'CN',
    id: 'Beijing',
    uniq: 44,

    region: 'AS',
    currency: {
      code: 'CNY',
      content: 'Chinese yuan',
      symbol: '¥',
    },
    language: {
      code: 'zh',
      content: 'Chinese',
    },
    imgPath: 'https://flagcdn.com/cn.svg',
  },
  {
    content: 'Christmas Island',
    code: 'CX',
    id: 'Flying Fish Cove',
    uniq: 45,

    region: 'OC',
    currency: {
      code: 'AUD',
      content: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/cx.svg',
  },
  {
    content: 'Cocos (Keeling) Islands',
    code: 'CC',
    id: 'West Island',
    uniq: 46,

    region: 'OC',
    currency: {
      code: 'AUD',
      content: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/cc.svg',
  },
  {
    content: 'Colombia',
    code: 'CO',
    id: 'Bogotá',
    region: 'SA',
    uniq: 47,

    currency: {
      code: 'COP',
      content: 'Colombian peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/co.svg',
  },
  {
    content: 'Comoros',
    code: 'KM',
    id: 'Moroni',
    uniq: 48,

    region: 'AF',
    currency: {
      code: 'KMF',
      content: 'Comorian franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/km.svg',
  },
  {
    content: 'Congo',
    code: 'CG',
    id: 'Brazzaville',
    region: 'AF',
    uniq: 49,

    currency: {
      code: 'XAF',
      content: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/cg.svg',
  },
  {
    content: 'Congo (Democratic Republic of the)',
    code: 'CD',
    id: 'Kinshasa',
    uniq: 50,

    region: 'AF',
    currency: {
      code: 'CDF',
      content: 'Congolese franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/cd.svg',
  },
  {
    content: 'Cook Islands',
    code: 'CK',
    id: 'Avarua',
    uniq: 51,

    region: 'OC',
    currency: {
      code: 'NZD',
      content: 'New Zealand dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ck.svg',
  },
  {
    content: 'Costa Rica',
    code: 'CR',
    id: 'San José',
    uniq: 52,

    region: 'NA',
    currency: {
      code: 'CRC',
      content: 'Costa Rican colón',
      symbol: '₡',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/cr.svg',
  },
  {
    content: 'Croatia',
    code: 'HR',
    id: 'Zagreb',
    uniq: 53,

    region: 'EU',
    currency: {
      code: 'HRK',
      content: 'Croatian kuna',
      symbol: 'kn',
    },
    language: {
      code: 'hr',
      content: 'Croatian',
    },
    imgPath: 'https://flagcdn.com/hr.svg',
  },
  {
    content: 'Cuba',
    code: 'CU',
    id: 'Havana',
    region: 'NA',
    uniq: 54,

    currency: {
      code: 'CUC',
      content: 'Cuban convertible peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/cu.svg',
  },
  {
    content: 'Curaçao',
    code: 'CW',
    id: 'Willemstad',
    uniq: 55,

    region: 'SA',
    currency: {
      code: 'ANG',
      content: 'Netherlands Antillean guilder',
      symbol: 'ƒ',
    },
    language: {
      code: 'nl',
      content: 'Dutch',
    },
    imgPath: 'https://flagcdn.com/cw.svg',
  },
  {
    content: 'Cyprus',
    code: 'CY',
    id: 'Nicosia',
    region: 'EU',
    uniq: 56,

    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'tr',
      content: 'Turkish',
    },
    imgPath: 'https://flagcdn.com/cy.svg',
  },
  {
    content: 'Czech Republic',
    code: 'CZ',
    id: 'Prague',
    region: 'EU',
    uniq: 57,

    currency: {
      code: 'CZK',
      content: 'Czech koruna',
      symbol: 'Kč',
    },
    language: {
      code: 'cs',
      content: 'Czech',
    },
    imgPath: 'https://flagcdn.com/cz.svg',
  },
  {
    content: 'Denmark',
    code: 'DK',
    id: 'Copenhagen',
    uniq: 58,

    region: 'EU',
    currency: {
      code: 'DKK',
      content: 'Danish krone',
      symbol: 'kr',
    },
    language: {
      code: 'da',
      content: 'Danish',
    },
    imgPath: 'https://flagcdn.com/dk.svg',
  },
  {
    content: 'Djibouti',
    code: 'DJ',
    id: 'Djibouti',
    uniq: 59,

    region: 'AF',
    currency: {
      code: 'DJF',
      content: 'Djiboutian franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/dj.svg',
  },
  {
    content: 'Dominica',
    code: 'DM',
    id: 'Roseau',
    uniq: 60,

    region: 'NA',
    currency: {
      code: 'XCD',
      content: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/dm.svg',
  },
  {
    content: 'Dominican Republic',
    code: 'DO',
    id: 'Santo Domingo',
    uniq: 61,

    region: 'NA',
    currency: {
      code: 'DOP',
      content: 'Dominican peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/do.svg',
  },
  {
    content: 'Ecuador',
    code: 'EC',
    id: 'Quito',
    region: 'SA',
    uniq: 62,

    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/ec.svg',
  },
  {
    content: 'Egypt',
    code: 'EG',
    id: 'Cairo',
    region: 'AF',
    uniq: 63,

    currency: {
      code: 'EGP',
      content: 'Egyptian pound',
      symbol: '£',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/eg.svg',
  },
  {
    content: 'El Salvador',
    code: 'SV',
    id: 'San Salvador',
    uniq: 64,

    region: 'NA',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/sv.svg',
  },
  {
    content: 'Equatorial Guinea',
    code: 'GQ',
    id: 'Malabo',
    uniq: 65,

    region: 'AF',
    currency: {
      code: 'XAF',
      content: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'es',
      iso639_2: 'spa',
      content: 'Spanish',
      nativecontent: 'Español',
    },
    imgPath: 'https://flagcdn.com/gq.svg',
  },
  {
    content: 'Eritrea',
    code: 'ER',
    id: 'Asmara',
    region: 'AF',
    uniq: 66,

    currency: {
      code: 'ERN',
      content: 'Eritrean nakfa',
      symbol: 'Nfk',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/er.svg',
  },
  {
    content: 'Estonia',
    code: 'EE',
    id: 'Tallinn',
    region: 'EU',
    uniq: 67,

    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'et',
      content: 'Estonian',
    },
    imgPath: 'https://flagcdn.com/ee.svg',
  },
  {
    content: 'Ethiopia',
    code: 'ET',
    id: 'Addis Ababa',
    uniq: 68,

    region: 'AF',
    currency: {
      code: 'ETB',
      content: 'Ethiopian birr',
      symbol: 'Br',
    },
    language: {
      code: 'am',
      content: 'Amharic',
    },
    imgPath: 'https://flagcdn.com/et.svg',
  },
  {
    content: 'Falkland Islands (Malvinas)',
    code: 'FK',
    id: 'Stanley',
    uniq: 69,

    region: 'SA',
    currency: {
      code: 'FKP',
      content: 'Falkland Islands pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/fk.svg',
  },
  {
    content: 'Faroe Islands',
    code: 'FO',
    id: 'Tórshavn',
    uniq: 70,

    region: 'EU',
    currency: {
      code: 'DKK',
      content: 'Danish krone',
      symbol: 'kr',
    },
    language: {
      code: 'fo',
      content: 'Faroese',
    },
    imgPath: 'https://flagcdn.com/fo.svg',
  },
  {
    content: 'Fiji',
    code: 'FJ',
    id: 'Suva',
    uniq: 71,

    region: 'OC',
    currency: {
      code: 'FJD',
      content: 'Fijian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/fj.svg',
  },

  {
    content: 'France',
    code: 'FR',
    id: 'Paris',
    uniq: 73,

    region: 'EU',
    demonym: 'French',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/fr.svg',
  },
  {
    content: 'French Guiana',
    code: 'GF',
    id: 'Cayenne',
    uniq: 74,

    region: 'SA',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/gf.svg',
  },
  {
    content: 'French Polynesia',
    code: 'PF',
    id: 'Papeetē',
    uniq: 75,

    region: 'OC',
    currency: {
      code: 'XPF',
      content: 'CFP franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/pf.svg',
  },
  {
    content: 'French Southern Territories',
    code: 'TF',
    id: 'Port-aux-Français',
    uniq: 76,

    region: 'AF',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/tf.svg',
  },
  {
    content: 'Gabon',
    code: 'GA',
    id: 'Libreville',
    uniq: 77,

    region: 'AF',
    currency: {
      code: 'XAF',
      content: 'Central African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/ga.svg',
  },
  {
    content: 'Gambia',
    code: 'GM',
    id: 'Banjul',
    region: 'AF',
    uniq: 78,

    currency: {
      code: 'GMD',
      content: 'Gambian dalasi',
      symbol: 'D',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/gm.svg',
  },
  {
    content: 'Georgia',
    code: 'GE',
    id: 'Tbilisi',
    uniq: 79,

    region: 'AS',
    currency: {
      code: 'GEL',
      content: 'Georgian Lari',
      symbol: 'ლ',
    },
    language: {
      code: 'ka',
      content: 'Georgian',
    },
    imgPath: 'https://flagcdn.com/ge.svg',
  },
  {
    content: 'Germany',
    code: 'DE',
    id: 'Berlin',
    uniq: 80,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'de',
      content: 'German',
    },
    imgPath: 'https://flagcdn.com/de.svg',
  },
  {
    content: 'Ghana',
    code: 'GH',
    id: 'Accra',
    uniq: 81,

    region: 'AF',
    currency: {
      code: 'GHS',
      content: 'Ghanaian cedi',
      symbol: '₵',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/gh.svg',
  },
  {
    content: 'Gibraltar',
    code: 'GI',
    id: 'Gibraltar',
    uniq: 82,

    region: 'EU',
    currency: {
      code: 'GIP',
      content: 'Gibraltar pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/gi.svg',
  },
  {
    content: 'Greece',
    code: 'GR',
    id: 'Athens',
    uniq: 83,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'el',
      content: 'Greek (modern)',
    },
    imgPath: 'https://flagcdn.com/gr.svg',
  },
  {
    content: 'Greenland',
    code: 'GL',
    id: 'Nuuk',
    uniq: 84,

    region: 'NA',
    currency: {
      code: 'DKK',
      content: 'Danish krone',
      symbol: 'kr',
    },
    language: {
      code: 'kl',
      content: 'Kalaallisut',
    },
    imgPath: 'https://flagcdn.com/gl.svg',
  },
  {
    content: 'Grenada',
    code: 'GD',
    uniq: 85,

    id: "St. George's",
    region: 'NA',
    currency: {
      code: 'XCD',
      content: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/gd.svg',
  },
  {
    content: 'Guadeloupe',
    code: 'GP',
    id: 'Basse-Terre',
    uniq: 86,

    region: 'NA',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/gp.svg',
  },
  {
    content: 'Guam',
    code: 'GU',
    id: 'Hagåtña',
    uniq: 87,

    region: 'OC',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/gu.svg',
  },
  {
    content: 'Guatemala',
    code: 'GT',
    id: 'Guatemala City',
    uniq: 88,

    region: 'NA',
    currency: {
      code: 'GTQ',
      content: 'Guatemalan quetzal',
      symbol: 'Q',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/gt.svg',
  },
  {
    content: 'Guernsey',
    code: 'GG',
    id: 'St. Peter Port',
    uniq: 89,

    region: 'EU',
    currency: {
      code: 'GBP',
      content: 'British pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/gg.svg',
  },
  {
    content: 'Guinea',
    code: 'GN',
    id: 'Conakry',
    uniq: 90,

    region: 'AF',
    currency: {
      code: 'GNF',
      content: 'Guinean franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/gn.svg',
  },
  {
    content: 'Guinea-Bissau',
    code: 'GW',
    id: 'Bissau',
    uniq: 91,

    region: 'AF',
    currency: {
      code: 'XOF',
      content: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'pt',
      content: 'Portuguese',
    },
    imgPath: 'https://flagcdn.com/gw.svg',
  },
  {
    content: 'Guyana',
    code: 'GY',
    id: 'Georgetown',
    uniq: 92,

    region: 'SA',
    currency: {
      code: 'GYD',
      content: 'Guyanese dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/gy.svg',
  },
  {
    content: 'Haiti',
    code: 'HT',
    id: 'Port-au-Prince',
    region: 'Americas',
    uniq: 93,

    currency: {
      code: 'HTG',
      content: 'Haitian gourde',
      symbol: 'G',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/ht.svg',
  },
  {
    content: 'Heard Island and McDonald Islands',
    code: 'HM',
    uniq: 94,

    id: '',
    region: '',
    currency: {
      code: 'AUD',
      content: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/hm.svg',
  },
  {
    content: 'Holy See',
    code: 'VA',
    id: 'Rome',
    uniq: 95,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/va.svg',
  },
  {
    content: 'Honduras',
    code: 'HN',
    id: 'Tegucigalpa',
    uniq: 96,

    region: 'NA',
    currency: {
      code: 'HNL',
      content: 'Honduran lempira',
      symbol: 'L',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/hn.svg',
  },
  {
    content: 'Hong Kong',
    code: 'HK',
    id: 'City of Victoria',
    uniq: 97,

    region: 'AS',
    currency: {
      code: 'HKD',
      content: 'Hong Kong dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/hk.svg',
  },
  {
    content: 'Hungary',
    code: 'HU',
    id: 'Budapest',
    uniq: 98,

    region: 'EU',
    currency: {
      code: 'HUF',
      content: 'Hungarian forint',
      symbol: 'Ft',
    },
    language: {
      code: 'hu',
      content: 'Hungarian',
    },
    imgPath: 'https://flagcdn.com/hu.svg',
  },
  {
    content: 'Iceland',
    code: 'IS',
    id: 'Reykjavík',
    uniq: 99,

    region: 'EU',
    currency: {
      code: 'ISK',
      content: 'Icelandic króna',
      symbol: 'kr',
    },
    language: {
      code: 'is',
      content: 'Icelandic',
    },
    imgPath: 'https://flagcdn.com/is.svg',
  },
  {
    content: 'India',
    code: 'IN',
    id: 'New Delhi',
    uniq: 100,

    region: 'AS',
    currency: {
      code: 'INR',
      content: 'Indian rupee',
      symbol: '₹',
    },
    language: {
      code: 'hi',
      content: 'Hindi',
    },
    imgPath: 'https://flagcdn.com/in.svg',
  },
  {
    content: 'Indonesia',
    code: 'ID',
    id: 'Jakarta',
    uniq: 101,

    region: 'AS',
    currency: {
      code: 'IDR',
      content: 'Indonesian rupiah',
      symbol: 'Rp',
    },
    language: {
      code: 'id',
      content: 'Indonesian',
    },
    imgPath: 'https://flagcdn.com/id.svg',
  },
  {
    content: 'Iran (Islamic Republic of)',
    code: 'IR',
    id: 'Tehran',
    uniq: 103,

    region: 'AS',
    currency: {
      code: 'IRR',
      content: 'Iranian rial',
      symbol: '﷼',
    },
    language: {
      code: 'fa',
      content: 'Persian (Farsi)',
    },
    imgPath: 'https://flagcdn.com/ir.svg',
  },
  {
    content: 'Iraq',
    code: 'IQ',
    id: 'Baghdad',
    uniq: 104,

    region: 'AS',
    currency: {
      code: 'IQD',
      content: 'Iraqi dinar',
      symbol: 'ع.د',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/iq.svg',
  },
  {
    content: 'Ireland',
    code: 'IE',
    id: 'Dublin',
    uniq: 105,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'ga',
      content: 'Irish',
    },
    imgPath: 'https://flagcdn.com/ie.svg',
  },
  {
    content: 'Isle of Man',
    code: 'IM',
    uniq: 106,

    id: 'Douglas',
    region: 'EU',
    currency: {
      code: 'GBP',
      content: 'British pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/im.svg',
  },
  {
    content: 'Israel',
    code: 'IL',
    id: 'Jerusalem',
    uniq: 107,

    region: 'AS',
    currency: {
      code: 'ILS',
      content: 'Israeli new shekel',
      symbol: '₪',
    },
    language: {
      code: 'he',
      content: 'Hebrew (modern)',
    },
    imgPath: 'https://flagcdn.com/il.svg',
  },
  {
    content: 'Italy',
    code: 'IT',
    id: 'Rome',
    region: 'EU',
    uniq: 108,

    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'it',
      content: 'Italian',
    },
    imgPath: 'https://flagcdn.com/it.svg',
  },
  {
    content: 'Jamaica',
    code: 'JM',
    id: 'Kingston',
    uniq: 109,

    region: 'NA',
    currency: {
      code: 'JMD',
      content: 'Jamaican dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/jm.svg',
  },
  {
    content: 'Japan',
    code: 'JP',
    id: 'Tokyo',
    uniq: 110,

    region: 'AS',
    currency: {
      code: 'JPY',
      content: 'Japanese yen',
      symbol: '¥',
    },
    language: {
      code: 'ja',
      content: 'Japanese',
    },
    imgPath: 'https://flagcdn.com/jp.svg',
  },
  {
    content: 'Jersey',
    code: 'JE',
    id: 'Saint Helier',
    uniq: 111,

    region: 'EU',
    currency: {
      code: 'GBP',
      content: 'British pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      iso639_2: 'eng',
      content: 'English',
      nativecontent: 'English',
    },
    imgPath: 'https://flagcdn.com/je.svg',
  },
  {
    content: 'Jordan',
    code: 'JO',
    id: 'Amman',
    uniq: 112,

    region: 'AS',
    currency: {
      code: 'JOD',
      content: 'Jordanian dinar',
      symbol: 'د.ا',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/jo.svg',
  },
  {
    content: 'Kazakhstan',
    code: 'KZ',
    id: 'Astana',
    uniq: 113,

    region: 'AS',
    currency: {
      code: 'KZT',
      content: 'Kazakhstani tenge',
      symbol: null,
    },
    language: {
      code: 'kk',
      content: 'Kazakh',
    },
    imgPath: 'https://flagcdn.com/kz.svg',
  },
  {
    content: 'Kenya',
    code: 'KE',
    id: 'Nairobi',
    uniq: 114,

    region: 'AF',
    currency: {
      code: 'KES',
      content: 'Kenyan shilling',
      symbol: 'Sh',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ke.svg',
  },
  {
    content: 'Kiribati',
    code: 'KI',
    id: 'South Tarawa',
    region: 'OC',
    uniq: 115,

    currency: {
      code: 'AUD',
      content: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ki.svg',
  },
  {
    content: 'Kuwait',
    code: 'KW',
    id: 'Kuwait City',
    uniq: 116,

    region: 'AS',
    currency: {
      code: 'KWD',
      content: 'Kuwaiti dinar',
      symbol: 'د.ك',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/kw.svg',
  },
  {
    content: 'Kyrgyzstan',
    code: 'KG',
    id: 'Bishkek',
    uniq: 117,

    region: 'AS',
    currency: {
      code: 'KGS',
      content: 'Kyrgyzstani som',
      symbol: 'с',
    },
    language: {
      code: 'ky',
      content: 'Kyrgyz',
    },
    imgPath: 'https://flagcdn.com/kg.svg',
  },
  {
    content: "Lao People's Democratic Republic",
    code: 'LA',
    uniq: 118,

    id: 'Vientiane',
    region: 'AS',
    currency: {
      code: 'LAK',
      content: 'Lao kip',
      symbol: '₭',
    },
    language: {
      code: 'lo',
      content: 'Lao',
    },
    imgPath: 'https://flagcdn.com/la.svg',
  },

  {
    content: 'Lebanon',
    code: 'LB',
    id: 'Beirut',
    uniq: 121,

    region: 'AS',
    currency: {
      code: 'LBP',
      content: 'Lebanese pound',
      symbol: 'ل.ل',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/lb.svg',
  },
  {
    content: 'Lesotho',
    code: 'LS',
    id: 'Maseru',
    uniq: 122,

    region: 'AF',
    currency: {
      code: 'LSL',
      content: 'Lesotho loti',
      symbol: 'L',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ls.svg',
  },
  {
    content: 'Liberia',
    code: 'LR',
    id: 'Monrovia',
    uniq: 123,

    region: 'AF',
    currency: {
      code: 'LRD',
      content: 'Liberian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/lr.svg',
  },
  {
    content: 'Libya',
    code: 'LY',
    id: 'Tripoli',
    uniq: 124,

    region: 'AF',
    currency: {
      code: 'LYD',
      content: 'Libyan dinar',
      symbol: 'ل.د',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/ly.svg',
  },
  {
    content: 'Liechtenstein',
    code: 'LI',
    id: 'Vaduz',
    uniq: 125,

    region: 'EU',
    currency: {
      code: 'CHF',
      content: 'Swiss franc',
      symbol: 'Fr',
    },
    language: {
      code: 'de',
      content: 'German',
    },
    imgPath: 'https://flagcdn.com/li.svg',
  },
  {
    content: 'Lithuania',
    code: 'LT',
    id: 'Vilnius',
    uniq: 126,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'lt',
      content: 'Lithuanian',
    },
    imgPath: 'https://flagcdn.com/lt.svg',
  },
  {
    content: 'Luxembourg',
    code: 'LU',
    id: 'Luxembourg',
    uniq: 127,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/lu.svg',
  },
  {
    content: 'Macao',
    code: 'MO',
    id: '',
    uniq: 128,

    region: 'AS',
    currency: {
      code: 'MOP',
      content: 'Macanese pataca',
      symbol: 'P',
    },
    language: {
      code: 'zh',
      content: 'Chinese',
    },
    imgPath: 'https://flagcdn.com/mo.svg',
  },
  {
    content: 'Macedonia (the former Yugoslav Republic of)',
    code: 'MK',
    id: 'Skopje',
    uniq: 129,

    region: 'EU',
    currency: {
      code: 'MKD',
      content: 'Macedonian denar',
      symbol: 'ден',
    },
    language: {
      code: 'mk',
      content: 'Macedonian',
    },
    imgPath: 'https://flagcdn.com/mk.svg',
  },
  {
    content: 'Madagascar',
    code: 'MG',
    id: 'Antananarivo',
    uniq: 130,

    region: 'AF',
    currency: {
      code: 'MGA',
      content: 'Malagasy ariary',
      symbol: 'Ar',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/mg.svg',
  },
  {
    content: 'Malawi',
    code: 'MW',
    id: 'Lilongwe',
    uniq: 131,

    region: 'AF',
    currency: {
      code: 'MWK',
      content: 'Malawian kwacha',
      symbol: 'MK',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/mw.svg',
  },
  {
    content: 'Malaysia',
    code: 'MY',
    id: 'Kuala Lumpur',
    uniq: 132,

    region: 'AS',
    currency: {
      code: 'MYR',
      content: 'Malaysian ringgit',
      symbol: 'RM',
    },
    language: {
      code: null,
      content: 'Malaysian',
    },
    imgPath: 'https://flagcdn.com/my.svg',
  },
  {
    content: 'Maldives',
    code: 'MV',
    id: 'Malé',
    region: 'AS',
    uniq: 133,

    currency: {
      code: 'MVR',
      content: 'Maldivian rufiyaa',
      symbol: '.ރ',
    },
    language: {
      code: 'dv',
      content: 'Divehi',
    },
    imgPath: 'https://flagcdn.com/mv.svg',
  },
  {
    content: 'Mali',
    code: 'ML',
    id: 'Bamako',
    uniq: 134,

    region: 'AF',
    currency: {
      code: 'XOF',
      content: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/ml.svg',
  },
  {
    content: 'Malta',
    code: 'MT',
    id: 'Valletta',
    uniq: 135,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'mt',
      content: 'Maltese',
    },
    imgPath: 'https://flagcdn.com/mt.svg',
  },
  {
    content: 'Marshall Islands',
    code: 'MH',
    id: 'Majuro',
    uniq: 136,

    region: 'OC',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/mh.svg',
  },
  {
    content: 'Martinique',
    code: 'MQ',
    id: 'Fort-de-France',
    uniq: 137,

    region: 'Americas',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/mq.svg',
  },
  {
    content: 'Mauritania',
    code: 'MR',
    id: 'Nouakchott',
    uniq: 138,

    region: 'AF',
    currency: {
      code: 'MRO',
      content: 'Mauritanian ouguiya',
      symbol: 'UM',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/mr.svg',
  },
  {
    content: 'Mauritius',
    code: 'MU',
    id: 'Port Louis',
    uniq: 139,

    region: 'AF',
    currency: {
      code: 'MUR',
      content: 'Mauritian rupee',
      symbol: '₨',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/mu.svg',
  },
  {
    content: 'Mayotte',
    code: 'YT',
    id: 'Mamoudzou',
    uniq: 140,

    region: 'AF',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/yt.svg',
  },
  {
    content: 'Mexico',
    code: 'MX',
    id: 'Mexico City',
    uniq: 141,

    region: 'NA',
    currency: {
      code: 'MXN',
      content: 'Mexican peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/mx.svg',
  },
  {
    content: 'Micronesia (Federated States of)',
    code: 'FM',
    id: 'Palikir',
    uniq: 142,

    region: 'OC',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/fm.svg',
  },
  {
    content: 'Moldova (Republic of)',
    code: 'MD',
    id: 'Chișinău',
    uniq: 143,

    region: 'EU',
    currency: {
      code: 'MDL',
      content: 'Moldovan leu',
      symbol: 'L',
    },
    language: {
      code: 'ro',
      content: 'Romanian',
    },
    imgPath: 'https://flagcdn.com/md.svg',
  },
  {
    content: 'Monaco',
    code: 'MC',
    id: 'Monaco',
    uniq: 144,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/mc.svg',
  },
  {
    content: 'Mongolia',
    code: 'MN',
    id: 'Ulan Bator',
    uniq: 145,

    region: 'AS',
    currency: {
      code: 'MNT',
      content: 'Mongolian tögrög',
      symbol: '₮',
    },
    language: {
      code: 'mn',
      content: 'Mongolian',
    },
    imgPath: 'https://flagcdn.com/mn.svg',
  },
  {
    content: 'Montenegro',
    code: 'ME',
    id: 'Podgorica',
    region: 'EU',
    uniq: 146,

    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'sr',
      content: 'Serbian',
    },
    imgPath: 'https://flagcdn.com/me.svg',
  },
  {
    content: 'Montserrat',
    code: 'MS',
    id: 'Plymouth',
    uniq: 147,

    region: 'NA',
    currency: {
      code: 'XCD',
      content: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ms.svg',
  },
  {
    content: 'Morocco',
    code: 'MA',
    id: 'Rabat',
    region: 'AF',
    uniq: 148,

    currency: {
      code: 'MAD',
      content: 'Moroccan dirham',
      symbol: 'د.م.',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/ma.svg',
  },
  {
    content: 'Mozambique',
    code: 'MZ',
    id: 'Maputo',
    uniq: 149,

    region: 'AF',
    currency: {
      code: 'MZN',
      content: 'Mozambican metical',
      symbol: 'MT',
    },
    language: {
      code: 'pt',
      content: 'Portuguese',
    },
    imgPath: 'https://flagcdn.com/mz.svg',
  },
  {
    content: 'Myanmar',
    code: 'MM',
    id: 'Naypyidaw',
    uniq: 150,

    region: 'AS',
    currency: {
      code: 'MMK',
      content: 'Burmese kyat',
      symbol: 'Ks',
    },
    language: {
      code: 'my',
      content: 'Burmese',
    },
    imgPath: 'https://flagcdn.com/mm.svg',
  },
  {
    content: 'Namibia',
    code: 'NA',
    id: 'Windhoek',
    uniq: 151,

    region: 'AF',
    currency: {
      code: 'NAD',
      content: 'Namibian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/na.svg',
  },
  {
    content: 'Nauru',
    code: 'NR',
    id: 'Yaren',
    uniq: 152,

    region: 'OC',
    currency: {
      code: 'AUD',
      content: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/nr.svg',
  },
  {
    content: 'Nepal',
    code: 'NP',
    id: 'Kathmandu',
    uniq: 153,

    region: 'AS',
    currency: {
      code: 'NPR',
      content: 'Nepalese rupee',
      symbol: '₨',
    },
    language: {
      code: 'ne',
      content: 'Nepali',
    },
    imgPath: 'https://flagcdn.com/np.svg',
  },
  {
    content: 'Netherlands',
    code: 'NL',
    id: 'Amsterdam',
    uniq: 154,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'nl',
      content: 'Dutch',
    },
    imgPath: 'https://flagcdn.com/nl.svg',
  },
  {
    content: 'New Caledonia',
    code: 'NC',
    id: 'Nouméa',
    uniq: 155,

    region: 'OC',
    currency: {
      code: 'XPF',
      content: 'CFP franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/nc.svg',
  },
  {
    content: 'New Zealand',
    code: 'NZ',
    id: 'Wellington',
    uniq: 156,

    region: 'OC',
    currency: {
      code: 'NZD',
      content: 'New Zealand dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/nz.svg',
  },
  {
    content: 'Nicaragua',
    code: 'NI',
    id: 'Managua',
    uniq: 157,

    region: 'NA',
    currency: {
      code: 'NIO',
      content: 'Nicaraguan córdoba',
      symbol: 'C$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/ni.svg',
  },
  {
    content: 'Niger',
    code: 'NE',
    id: 'Niamey',
    uniq: 158,

    region: 'AF',
    currency: {
      code: 'XOF',
      content: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/ne.svg',
  },
  {
    content: 'Nigeria',
    code: 'NG',
    id: 'Abuja',
    uniq: 159,

    region: 'AF',
    currency: {
      code: 'NGN',
      content: 'Nigerian naira',
      symbol: '₦',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ng.svg',
  },
  {
    content: 'Niue',
    code: 'NU',
    id: 'Alofi',
    uniq: 160,

    region: 'OC',
    currency: {
      code: 'NZD',
      content: 'New Zealand dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/nu.svg',
  },
  {
    content: 'Norfolk Island',
    code: 'NF',
    id: 'Kingston',
    uniq: 161,

    region: 'OC',
    currency: {
      code: 'AUD',
      content: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/nf.svg',
  },
  {
    content: "Korea (Democratic People's Republic of)",
    code: 'KP',
    id: 'Pyongyang',
    uniq: 162,

    region: 'AS',
    currency: {
      code: 'KPW',
      content: 'North Korean won',
      symbol: '₩',
    },
    language: {
      code: 'ko',
      content: 'Korean',
    },
    imgPath: 'https://flagcdn.com/kp.svg',
  },
  {
    content: 'Northern Mariana Islands',
    code: 'MP',
    id: 'Saipan',
    uniq: 163,

    region: 'OC',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/mp.svg',
  },
  {
    content: 'Norway',
    code: 'NO',
    uniq: 164,

    id: 'Oslo',
    region: 'EU',
    currency: {
      code: 'NOK',
      content: 'Norwegian krone',
      symbol: 'kr',
    },
    language: {
      code: 'no',
      content: 'Norwegian',
    },
    imgPath: 'https://flagcdn.com/no.svg',
  },
  {
    content: 'Oman',
    code: 'OM',
    id: 'Muscat',
    uniq: 165,

    region: 'AS',
    currency: {
      code: 'OMR',
      content: 'Omani rial',
      symbol: 'ر.ع.',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/om.svg',
  },
  {
    content: 'Pakistan',
    code: 'PK',
    id: 'Islamabad',
    uniq: 166,

    region: 'AS',
    currency: {
      code: 'PKR',
      content: 'Pakistani rupee',
      symbol: '₨',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/pk.svg',
  },
  {
    content: 'Palau',
    code: 'PW',
    id: 'Ngerulmud',
    uniq: 167,

    region: 'OC',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/pw.svg',
  },
  {
    content: 'Palestine, State of',
    code: 'PS',
    id: 'Ramallah',
    uniq: 168,

    region: 'AS',
    currency: {
      code: 'ILS',
      content: 'Israeli new sheqel',
      symbol: '₪',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/ps.svg',
  },
  {
    content: 'Panama',
    code: 'PA',
    id: 'Panama City',
    uniq: 169,

    region: 'NA',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/pa.svg',
  },
  {
    content: 'Papua New Guinea',
    code: 'PG',
    id: 'Port Moresby',
    uniq: 170,

    region: 'OC',
    currency: {
      code: 'PGK',
      content: 'Papua New Guinean kina',
      symbol: 'K',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/pg.svg',
  },
  {
    content: 'Paraguay',
    code: 'PY',
    id: 'Asunción',
    uniq: 171,

    region: 'SA',
    currency: {
      code: 'PYG',
      content: 'Paraguayan guaraní',
      symbol: '₲',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/py.svg',
  },
  {
    content: 'Peru',
    code: 'PE',
    id: 'Lima',
    region: 'SA',
    uniq: 172,

    currency: {
      code: 'PEN',
      content: 'Peruvian sol',
      symbol: 'S/.',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/pe.svg',
  },
  {
    content: 'Philippines',
    code: 'PH',
    id: 'Manila',
    uniq: 173,

    region: 'AS',
    currency: {
      code: 'PHP',
      content: 'Philippine peso',
      symbol: '₱',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ph.svg',
  },
  {
    content: 'Pitcairn',
    code: 'PN',
    id: 'Adamstown',
    uniq: 174,

    region: 'OC',
    currency: {
      code: 'NZD',
      content: 'New Zealand dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/pn.svg',
  },
  {
    content: 'Poland',
    code: 'PL',
    id: 'Warsaw',
    uniq: 175,

    region: 'EU',
    currency: {
      code: 'PLN',
      content: 'Polish złoty',
      symbol: 'zł',
    },
    language: {
      code: 'pl',
      content: 'Polish',
    },
    imgPath: 'https://flagcdn.com/pl.svg',
  },
  {
    content: 'Portugal',
    code: 'PT',
    id: 'Lisbon',
    uniq: 176,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'pt',
      content: 'Portuguese',
    },
    imgPath: 'https://flagcdn.com/pt.svg',
  },
  {
    content: 'Puerto Rico',
    code: 'PR',
    id: 'San Juan',
    uniq: 177,

    region: 'NA',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/pr.svg',
  },
  {
    content: 'Qatar',
    code: 'QA',
    id: 'Doha',
    uniq: 178,

    region: 'AS',
    currency: {
      code: 'QAR',
      content: 'Qatari riyal',
      symbol: 'ر.ق',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/qa.svg',
  },
  {
    content: 'Republic of Kosovo',
    code: 'XK',
    id: 'Pristina',
    uniq: 179,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'sq',
      content: 'Albanian',
    },
    imgPath: 'https://flagcdn.com/xk.svg',
  },
  {
    content: 'Réunion',
    code: 'RE',
    id: 'Saint-Denis',
    uniq: 180,

    region: 'AF',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/re.svg',
  },
  {
    content: 'Romania',
    code: 'RO',
    id: 'Bucharest',
    uniq: 181,

    region: 'EU',
    currency: {
      code: 'RON',
      content: 'Romanian leu',
      symbol: 'lei',
    },
    language: {
      code: 'ro',
      content: 'Romanian',
    },
    imgPath: 'https://flagcdn.com/ro.svg',
  },
  {
    content: 'Rwanda',
    code: 'RW',
    id: 'Kigali',
    uniq: 182,

    region: 'AF',
    currency: {
      code: 'RWF',
      content: 'Rwandan franc',
      symbol: 'Fr',
    },
    language: {
      code: 'rw',
      content: 'Kinyarwanda',
    },
    imgPath: 'https://flagcdn.com/rw.svg',
  },
  {
    content: 'Saint Barthélemy',
    code: 'BL',
    id: 'Gustavia',
    uniq: 183,

    region: 'NA',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/bl.svg',
  },
  {
    content: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
    id: 'Jamestown',
    uniq: 184,

    region: 'AF',
    currency: {
      code: 'SHP',
      content: 'Saint Helena pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/sh.svg',
  },
  {
    content: 'Saint Kitts and Nevis',
    code: 'KN',
    id: 'Basseterre',
    uniq: 185,

    region: 'NA',
    currency: {
      code: 'XCD',
      content: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/kn.svg',
  },
  {
    content: 'Saint Lucia',
    code: 'LC',
    id: 'Castries',
    uniq: 186,

    region: 'NA',
    currency: {
      code: 'XCD',
      content: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/lc.svg',
  },
  {
    content: 'Saint Martin (French part)',
    code: 'MF',
    id: 'Marigot',
    uniq: 187,

    region: 'NA',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/mf.svg',
  },
  {
    content: 'Saint Pierre and Miquelon',
    code: 'PM',
    id: 'Saint-Pierre',
    uniq: 188,

    region: 'NA',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/pm.svg',
  },
  {
    content: 'Saint Vincent and the Grenadines',
    code: 'VC',
    id: 'Kingstown',
    uniq: 189,

    region: 'NA',
    currency: {
      code: 'XCD',
      content: 'East Caribbean dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/vc.svg',
  },
  {
    content: 'Samoa',
    code: 'WS',
    id: 'Apia',
    uniq: 190,

    region: 'OC',
    currency: {
      code: 'WST',
      content: 'Samoan tālā',
      symbol: 'T',
    },
    language: {
      code: 'sm',
      content: 'Samoan',
    },
    imgPath: 'https://flagcdn.com/ws.svg',
  },
  {
    content: 'San Marino',
    code: 'SM',
    uniq: 191,

    id: 'City of San Marino',
    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'it',
      content: 'Italian',
    },
    imgPath: 'https://flagcdn.com/sm.svg',
  },
  {
    content: 'Sao Tome and Principe',
    code: 'ST',
    id: 'São Tomé',
    uniq: 192,

    region: 'AF',
    currency: {
      code: 'STD',
      content: 'São Tomé and Príncipe dobra',
      symbol: 'Db',
    },
    language: {
      code: 'pt',
      content: 'Portuguese',
    },
    imgPath: 'https://flagcdn.com/st.svg',
  },
  {
    content: 'Saudi Arabia',
    code: 'SA',
    id: 'Riyadh',
    uniq: 193,

    region: 'AS',
    currency: {
      code: 'SAR',
      content: 'Saudi riyal',
      symbol: 'ر.س',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/sa.svg',
  },
  {
    content: 'Senegal',
    code: 'SN',
    id: 'Dakar',
    uniq: 194,

    region: 'AF',
    currency: {
      code: 'XOF',
      content: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/sn.svg',
  },
  {
    content: 'Serbia',
    code: 'RS',
    id: 'Belgrade',
    uniq: 195,

    region: 'EU',
    currency: {
      code: 'RSD',
      content: 'Serbian dinar',
      symbol: 'дин.',
    },
    language: {
      code: 'sr',
      content: 'Serbian',
    },
    imgPath: 'https://flagcdn.com/rs.svg',
  },
  {
    content: 'Seychelles',
    code: 'SC',
    id: 'Victoria',
    uniq: 196,

    region: 'AF',
    currency: {
      code: 'SCR',
      content: 'Seychellois rupee',
      symbol: '₨',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/sc.svg',
  },
  {
    content: 'Sierra Leone',
    code: 'SL',
    id: 'Freetown',
    uniq: 197,

    region: 'AF',
    currency: {
      code: 'SLL',
      content: 'Sierra Leonean leone',
      symbol: 'Le',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/sl.svg',
  },
  {
    content: 'Singapore',
    code: 'SG',
    id: 'Singapore',
    uniq: 198,

    region: 'AS',
    currency: {
      code: 'SGD',
      content: 'Singapore dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/sg.svg',
  },
  {
    content: 'Sint Maarten (Dutch part)',
    code: 'SX',
    id: 'Philipsburg',
    region: 'Americas',
    uniq: 199,

    currency: {
      code: 'ANG',
      content: 'Netherlands Antillean guilder',
      symbol: 'ƒ',
    },
    language: {
      code: 'nl',
      content: 'Dutch',
    },
    imgPath: 'https://flagcdn.com/sx.svg',
  },
  {
    content: 'Slovakia',
    code: 'SK',
    id: 'Bratislava',
    uniq: 200,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'sk',
      content: 'Slovak',
    },
    imgPath: 'https://flagcdn.com/sk.svg',
  },

  {
    content: 'Solomon Islands',
    code: 'SB',
    id: 'Honiara',
    uniq: 202,

    region: 'OC',
    currency: {
      code: 'SBD',
      content: 'Solomon Islands dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/sb.svg',
  },
  {
    content: 'Somalia',
    code: 'SO',
    id: 'Mogadishu',
    uniq: 203,

    region: 'AF',
    currency: {
      code: 'SOS',
      content: 'Somali shilling',
      symbol: 'Sh',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/so.svg',
  },
  {
    content: 'South Africa',
    code: 'ZA',
    id: 'Pretoria',
    uniq: 204,

    region: 'AF',
    currency: {
      code: 'ZAR',
      content: 'South African rand',
      symbol: 'R',
    },
    language: {
      code: 'en',
      iso639_2: 'eng',
      content: 'English',
      nativecontent: 'English',
    },
    imgPath: 'https://flagcdn.com/za.svg',
  },
  {
    content: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    id: 'King Edward Point',
    uniq: 205,

    region: 'NA',
    currency: {
      code: 'GBP',
      content: 'British pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/gs.svg',
  },
  {
    content: 'Korea (Republic of)',
    code: 'KR',
    id: 'Seoul',
    uniq: 206,

    region: 'AS',
    currency: {
      code: 'KRW',
      content: 'South Korean won',
      symbol: '₩',
    },
    language: {
      code: 'ko',
      content: 'Korean',
    },
    imgPath: 'https://flagcdn.com/kr.svg',
  },
  {
    content: 'South Sudan',
    code: 'SS',
    id: 'Juba',
    uniq: 207,

    region: 'AF',
    currency: {
      code: 'SSP',
      content: 'South Sudanese pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ss.svg',
  },
  {
    content: 'Spain',
    code: 'ES',
    id: 'Madrid',
    uniq: 208,

    region: 'EU',
    currency: {
      code: 'EUR',
      content: 'Euro',
      symbol: '€',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/es.svg',
  },
  {
    content: 'Sri Lanka',
    code: 'LK',
    id: 'Colombo',
    uniq: 209,

    region: 'AS',
    currency: {
      code: 'LKR',
      content: 'Sri Lankan rupee',
      symbol: 'Rs',
    },
    language: {
      code: 'si',
      iso639_2: 'sin',
      content: 'Sinhalese',
      nativecontent: 'සිංහල',
    },
    imgPath: 'https://flagcdn.com/lk.svg',
  },
  {
    content: 'Sudan',
    code: 'SD',
    id: 'Khartoum',
    uniq: 210,

    region: 'AF',
    currency: {
      code: 'SDG',
      content: 'Sudanese pound',
      symbol: 'ج.س.',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/sd.svg',
  },
  {
    content: 'Suricontent',
    code: 'SR',
    id: 'Paramaribo',
    uniq: 211,

    region: 'SA',
    currency: {
      code: 'SRD',
      content: 'Suricontentse dollar',
      symbol: '$',
    },
    language: {
      code: 'nl',
      content: 'Dutch',
    },
    imgPath: 'https://flagcdn.com/sr.svg',
  },
  {
    content: 'Svalbard and Jan Mayen',
    code: 'SJ',
    id: 'Longyearbyen',
    uniq: 212,

    region: 'EU',
    currency: {
      code: 'NOK',
      content: 'Norwegian krone',
      symbol: 'kr',
    },
    language: {
      code: 'no',
      content: 'Norwegian',
    },
    imgPath: 'https://flagcdn.com/sj.svg',
  },
  {
    content: 'Swaziland',
    code: 'SZ',
    id: 'Lobamba',
    uniq: 213,

    region: 'AF',
    currency: {
      code: 'SZL',
      content: 'Swazi lilangeni',
      symbol: 'L',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/sz.svg',
  },
  {
    content: 'Sweden',
    code: 'SE',
    id: 'Stockholm',
    region: 'EU',
    uniq: 214,

    currency: {
      code: 'SEK',
      content: 'Swedish krona',
      symbol: 'kr',
    },
    language: {
      code: 'sv',
      content: 'Swedish',
    },
    imgPath: 'https://flagcdn.com/se.svg',
  },
  {
    content: 'Switzerland',
    code: 'CH',
    id: 'Bern',
    region: 'EU',
    uniq: 215,

    currency: {
      code: 'CHF',
      content: 'Swiss franc',
      symbol: 'Fr',
    },
    language: {
      code: 'de',
      content: 'German',
    },
    imgPath: 'https://flagcdn.com/ch.svg',
  },
  {
    content: 'Syrian Arab Republic',
    code: 'SY',
    id: 'Damascus',
    uniq: 216,

    region: 'AS',
    currency: {
      code: 'SYP',
      content: 'Syrian pound',
      symbol: '£',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/sy.svg',
  },
  {
    content: 'Taiwan',
    code: 'TW',
    id: 'Taipei',
    uniq: 217,

    region: 'AS',
    currency: {
      code: 'TWD',
      content: 'New Taiwan dollar',
      symbol: '$',
    },
    language: {
      code: 'zh',
      content: 'Chinese',
    },
    imgPath: 'https://flagcdn.com/tw.svg',
  },
  {
    content: 'Tajikistan',
    code: 'TJ',
    id: 'Dushanbe',
    uniq: 218,

    region: 'AS',
    currency: {
      code: 'TJS',
      content: 'Tajikistani somoni',
      symbol: 'ЅМ',
    },
    language: {
      code: 'tg',
      content: 'Tajik',
    },
    imgPath: 'https://flagcdn.com/tj.svg',
  },
  {
    content: 'Tanzania, United Republic of',
    code: 'TZ',
    id: 'Dodoma',
    uniq: 219,

    region: 'AF',
    currency: {
      code: 'TZS',
      content: 'Tanzanian shilling',
      symbol: 'Sh',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/tz.svg',
  },
  {
    content: 'Thailand',
    code: 'TH',
    id: 'Bangkok',
    uniq: 220,

    region: 'AS',
    currency: {
      code: 'THB',
      content: 'Thai baht',
      symbol: '฿',
    },
    language: {
      code: 'th',
      content: 'Thai',
    },
    imgPath: 'https://flagcdn.com/th.svg',
  },
  {
    content: 'Timor-Leste',
    code: 'TL',
    id: 'Dili',
    uniq: 221,

    region: 'AS',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'pt',
      content: 'Portuguese',
    },
    imgPath: 'https://flagcdn.com/tl.svg',
  },
  {
    content: 'Togo',
    code: 'TG',
    id: 'Lomé',
    region: 'AF',
    uniq: 222,

    currency: {
      code: 'XOF',
      content: 'West African CFA franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/tg.svg',
  },
  {
    content: 'Tokelau',
    code: 'TK',
    id: 'Fakaofo',
    uniq: 223,

    region: 'OC',
    currency: {
      code: 'NZD',
      content: 'New Zealand dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/tk.svg',
  },
  {
    content: 'Tonga',
    code: 'TO',
    id: "Nuku'alofa",
    uniq: 224,

    region: 'OC',
    currency: {
      code: 'TOP',
      content: 'Tongan paʻanga',
      symbol: 'T$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/to.svg',
  },
  {
    content: 'Trinidad and Tobago',
    code: 'TT',
    id: 'Port of Spain',
    uniq: 225,

    region: 'SA',
    currency: {
      code: 'TTD',
      content: 'Trinidad and Tobago dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/tt.svg',
  },
  {
    content: 'Tunisia',
    code: 'TN',
    id: 'Tunis',
    uniq: 226,

    region: 'AF',
    currency: {
      code: 'TND',
      content: 'Tunisian dinar',
      symbol: 'د.ت',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/tn.svg',
  },
  {
    content: 'Turkey',
    code: 'TR',
    id: 'Ankara',
    uniq: 227,

    region: 'AS',
    currency: {
      code: 'TRY',
      content: 'Turkish lira',
      symbol: null,
    },
    language: {
      code: 'tr',
      content: 'Turkish',
    },
    imgPath: 'https://flagcdn.com/tr.svg',
  },
  {
    content: 'Turkmenistan',
    code: 'TM',
    id: 'Ashgabat',
    uniq: 228,

    region: 'AS',
    currency: {
      code: 'TMT',
      content: 'Turkmenistan manat',
      symbol: 'm',
    },
    language: {
      code: 'tk',
      content: 'Turkmen',
    },
    imgPath: 'https://flagcdn.com/tm.svg',
  },
  {
    content: 'Turks and Caicos Islands',
    code: 'TC',
    id: 'Cockburn Town',
    uniq: 229,

    region: 'NA',
    currency: {
      code: 'USD',
      content: 'United States dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/tc.svg',
  },
  {
    content: 'Tuvalu',
    code: 'TV',
    id: 'Funafuti',
    uniq: 230,

    region: 'OC',
    currency: {
      code: 'AUD',
      content: 'Australian dollar',
      symbol: '$',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/tv.svg',
  },
  {
    content: 'Uganda',
    code: 'UG',
    id: 'Kampala',
    uniq: 231,

    region: 'AF',
    currency: {
      code: 'UGX',
      content: 'Ugandan shilling',
      symbol: 'Sh',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/ug.svg',
  },
  {
    content: 'Ukraine',
    code: 'UA',
    id: 'Kiev',
    uniq: 232,

    region: 'EU',
    currency: {
      code: 'UAH',
      content: 'Ukrainian hryvnia',
      symbol: '₴',
    },
    language: {
      code: 'uk',
      content: 'Ukrainian',
    },
    imgPath: 'https://flagcdn.com/ua.svg',
  },
  {
    content: 'United Arab Emirates',
    code: 'AE',
    id: 'Abu Dhabi',
    uniq: 233,

    region: 'AS',
    currency: {
      code: 'AED',
      content: 'United Arab Emirates dirham',
      symbol: 'د.إ',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/ae.svg',
  },
  {
    content: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GB',
    id: 'London',
    uniq: 234,

    region: 'EU',
    currency: {
      code: 'GBP',
      content: 'British pound',
      symbol: '£',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/gb.svg',
  },
  {
    content: 'Uruguay',
    code: 'UY',
    id: 'Montevideo',
    uniq: 237,

    region: 'SA',
    currency: {
      code: 'UYU',
      content: 'Uruguayan peso',
      symbol: '$',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/uy.svg',
  },
  {
    content: 'Uzbekistan',
    code: 'UZ',
    id: 'Tashkent',
    uniq: 238,

    region: 'AS',
    currency: {
      code: 'UZS',
      content: "Uzbekistani so'm",
      symbol: null,
    },
    language: {
      code: 'uz',
      content: 'Uzbek',
    },
    imgPath: 'https://flagcdn.com/uz.svg',
  },
  {
    content: 'Vanuatu',
    code: 'VU',
    id: 'Port Vila',
    uniq: 239,

    region: 'OC',
    currency: {
      code: 'VUV',
      content: 'Vanuatu vatu',
      symbol: 'Vt',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/vu.svg',
  },
  {
    content: 'Venezuela (Bolivarian Republic of)',
    code: 'VE',
    id: 'Caracas',
    region: 'SA',
    uniq: 240,

    currency: {
      code: 'VEF',
      content: 'Venezuelan bolívar',
      symbol: 'Bs F',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/ve.svg',
  },
  {
    content: 'Viet Nam',
    code: 'VN',
    id: 'Hanoi',
    region: 'AS',
    uniq: 241,

    currency: {
      code: 'VND',
      content: 'Vietcontentse đồng',
      symbol: '₫',
    },
    language: {
      code: 'vi',
      content: 'Vietcontentse',
    },
    imgPath: 'https://flagcdn.com/vn.svg',
  },
  {
    content: 'Wallis and Futuna',
    code: 'WF',
    id: 'Mata-Utu',
    uniq: 290,

    region: 'OC',
    currency: {
      code: 'XPF',
      content: 'CFP franc',
      symbol: 'Fr',
    },
    language: {
      code: 'fr',
      content: 'French',
    },
    imgPath: 'https://flagcdn.com/wf.svg',
  },
  {
    content: 'Western Sahara',
    code: 'EH',
    id: 'El Aaiún',
    uniq: 291,

    region: 'AF',
    currency: {
      code: 'MAD',
      content: 'Moroccan dirham',
      symbol: 'د.م.',
    },
    language: {
      code: 'es',
      content: 'Spanish',
    },
    imgPath: 'https://flagcdn.com/eh.svg',
  },
  {
    content: 'Yemen',
    code: 'YE',
    id: "Sana'a",
    uniq: 292,

    region: 'AS',
    currency: {
      code: 'YER',
      content: 'Yemeni rial',
      symbol: '﷼',
    },
    language: {
      code: 'ar',
      content: 'Arabic',
    },
    imgPath: 'https://flagcdn.com/ye.svg',
  },
  {
    content: 'Zambia',
    code: 'ZM',
    id: 'Lusaka',
    uniq: 293,

    region: 'AF',
    currency: {
      code: 'ZMW',
      content: 'Zambian kwacha',
      symbol: 'ZK',
    },
    language: {
      code: 'en',
      content: 'English',
    },
    imgPath: 'https://flagcdn.com/zm.svg',
  },
  {
    content: 'Zimbabwe',
    code: 'ZW',
    id: 'Harare',
    region: 'AF',
    uniq: 294,

    currency: {
      code: 'BWP',
      content: 'Botswana pula',
      symbol: 'P',
    },
    language: {
      code: 'en',
      iso639_2: 'eng',
      content: 'English',
      nativecontent: 'English',
    },
    imgPath: 'https://flagcdn.com/zw.svg',
  },
];
